import React, { ChangeEvent, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import validator from "validator";
import { AlertColor } from "@mui/material/Alert/Alert";

const App = () => {
  const theme = useTheme();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  type SnackbarState = {
    open: boolean;
    text: string;
    severity: AlertColor;
  };

  const [snackbarState, setSnackbarState] = React.useState<SnackbarState>({
    open: false,
    text: "",
    severity: "info",
  });

  const handleSnackbarClose = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  // App

  type Application = {
    id: string;
    title: string;
  };

  const apps: Application[] = [
    { id: "swift-note", title: "Swift Note" },
    { id: "currencier", title: "Currencier" },
    { id: "convertos", title: "Convertos" },
    { id: "just-calendar", title: "Just Calendar" },
    { id: "when-to-sleep", title: "When to Sleep" },
  ];

  // Hash

  const hash = location.hash.split("#")[1] ?? "";

  // Form

  type Form = {
    name: string;
    email: string;
    app: string;
    subject: string;
    text: string;
    sum: string;
  };

  const initialForm = {
    name: "",
    email: "",
    app: apps.map((item) => item.id).includes(hash) ? hash : apps[0].id,
    subject: "",
    text: "",
    sum: "",
  };

  const [form, setForm] = React.useState<Form>(initialForm);

  type Error = {
    key: keyof Form;
    text: string;
  };

  const [error, setErrors] = React.useState<Error[]>([]);

  const hasError = (key: keyof Form): boolean => {
    return error.map((item) => item.key).includes(key);
  };

  const getError = (key: keyof Form): string | undefined => {
    return error.find((item) => item.key === key)?.text;
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setForm({
      ...form,
      app: event.target.value as string,
    });
  };

  const handleTextChange = (
    key: keyof Form,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      [key]: event.target.value,
    });
  };

  const handleSubmit = () => {
    let errors: Error[] = [];
    const requiredFields: (keyof Form)[] = [
      "name",
      "email",
      "app",
      "subject",
      "text",
      "sum",
    ];
    requiredFields.forEach((key) => {
      if (!Boolean(form[key])) {
        errors.push({
          key: key,
          text: "Field is required",
        });
      }
    });
    setErrors(errors);
    if (errors.length !== 0) {
      return;
    }
    if (!validator.isEmail(form.email)) {
      errors.push({
        key: "email",
        text: "Enter correct email",
      });
    }
    if (form.sum !== "2") {
      errors.push({
        key: "sum",
        text: "Please enter correct sum",
      });
    }
    setErrors(errors);
    if (errors.length !== 0) {
      return;
    }
    setIsLoading(true);
    fetch("/php/submitmessage.php", {
      method: "POST",
      body: JSON.stringify(form),
    }).then((res) => {
      setIsLoading(false);
      setForm(initialForm);
      setSnackbarState({
        open: true,
        text: "Your message has been sent successfully!",
        severity: "success",
      });
    });
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ pt: theme.spacing(2), pb: theme.spacing(2) }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Kuzmenko Family Support</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you have any question, please contact me
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={form.name}
            name="name"
            type="text"
            label="Your Name"
            required
            fullWidth
            variant="outlined"
            error={hasError("name")}
            helperText={getError("name")}
            onChange={(event) => handleTextChange("name", event)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={form.email}
            name="email"
            type="email"
            label="Your Email"
            required
            fullWidth
            variant="outlined"
            error={hasError("email")}
            helperText={getError("email")}
            onChange={(event) => handleTextChange("email", event)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="app">Application</InputLabel>
            <Select
              labelId="app"
              required
              value={form.app}
              label="Application"
              onChange={handleSelectChange}
            >
              {apps.map((app) => (
                <MenuItem value={app.id} key={app.id}>
                  {app.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={form.subject}
            name="subject"
            required
            label="Subject"
            fullWidth
            variant="outlined"
            error={hasError("subject")}
            helperText={getError("subject")}
            onChange={(event) => handleTextChange("subject", event)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={form.text}
            label="Message"
            required
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            error={hasError("text")}
            helperText={getError("text")}
            onChange={(event) => handleTextChange("text", event)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={form.sum}
            label="1 + 1 ="
            required
            placeholder="Sum"
            fullWidth
            variant="outlined"
            error={hasError("sum")}
            helperText={getError("sum")}
            onChange={(event) => handleTextChange("sum", event)}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            size="large"
            fullWidth
            variant="contained"
            disableElevation
            sx={{ height: 56 }}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <Link
            variant="body1"
            color="textSecondary"
            underline="none"
            href="/privacy.html"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <Link
            variant="body1"
            color="textSecondary"
            underline="none"
            href="/terms.html"
            target="_blank"
          >
            Terms & Conditions
          </Link>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleSnackbarClose}
      >
        <Alert severity="success" variant="filled">
          {snackbarState.text}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default App;
